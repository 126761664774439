import { db } from './Firebase'

export interface GalleryImage {
  cloudId: string
  location: string
  caption: string
  description: string
}

export const getImages = async () => {
  const snapshot = await db.collection('images').get()
  return await snapshot.docs.map<GalleryImage>(
    doc => doc.data() as GalleryImage,
  )
}
