/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import React, { FC } from 'react'

type TextVariants =
  | 'sectionTitle'
  | 'body'
  | 'detail'
  | 'button'
  | 'more'
  | 'h4'

type SpanProps = React.ClassAttributes<HTMLSpanElement> &
  React.HTMLAttributes<HTMLSpanElement>

interface TextProps extends SpanProps {
  variant: TextVariants
}

const Text: FC<TextProps> = props => {
  const { variant, children, ...rest } = props

  const fontFamily = () => {
    switch (variant) {
      case 'sectionTitle':
      case 'h4':
        return 'Bitter'
      default:
        return 'Montserrat'
    }
  }
  const fontSize = (): string => {
    switch (variant) {
      case 'sectionTitle':
        return '1.5rem'
      case 'body':
        return '1rem'
      case 'detail':
        return '.8rem'
      case 'button':
      case 'more':
        return '.9rem'
      case 'h4':
        return '.7rem'
    }
  }

  const fontWeight = () => {
    switch (variant) {
      case 'sectionTitle':
        return 'bold'
      case 'more':
      case 'h4':
        return 600
    }
  }

  const letterSpacing = () => {
    switch (variant) {
      case 'sectionTitle':
      case 'h4':
        return 0.3
      case 'body':
        return 0.5
      case 'detail':
        return 1
      default:
        return 0
    }
  }

  return (
    <span
      css={{
        fontFamily: fontFamily(),
        fontSize: fontSize(),
        fontWeight: fontWeight(),
        letterSpacing: letterSpacing(),
      }}
      {...rest}
    >
      {children}
    </span>
  )
}

export default Text
