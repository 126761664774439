/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useTheme } from '@emotion/react'
import { useState } from 'react'
import { Image } from 'cloudinary-react'
import { submitContactForm } from '../api/contact'
import Button from '../components/Button'
import FormField from '../components/FormField'
import NavBar from '../components/NavBar'
import TextInput from '../components/TextInput'
import Text from '../components/Text'

const Contact = () => {
  const theme = useTheme()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const resetAll = () => {
    setName('')
    setEmail('')
    setSubject('')
    setMessage('')
  }

  const handleSubmit = () => {
    if (name && email && message) {
      submitContactForm({
        name,
        email,
        subject,
        message,
      })
      resetAll()
      setShowSuccessMessage(true)
    }
  }

  return (
    <div
      css={{
        display: 'grid',
        gridTemplateRows: `${theme.views.navHeight} auto`,
      }}
    >
      <NavBar css={{ backgroundColor: theme.colors.secondary }} />
      <div
        css={{
          paddingTop: theme.views.navHeight,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: theme.space.large,
        }}
      >
        <div
          css={{
            alignSelf: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            padding: theme.space.medium,
            marginRight: theme.space.large,
          }}
        >
          <Text variant="sectionTitle">Start your adventure</Text>
          <Text variant="body" css={{ marginTop: theme.space.large }}>
            Send us a message to find out more about our custom tour options and
            pricing. You can email us directly at faneva4@gmail.com.
          </Text>
          <Image
            cloudName="manga-tours"
            publicId="chameleon.jpg"
            width="400"
            crop="scale"
            css={{
              borderRadius: theme.radii.heavy,
              marginTop: theme.space.large,
            }}
          />
        </div>
        <div
          css={{
            display: 'grid',
            gap: theme.space.large,
            width: '100%',
          }}
        >
          <FormField label="Name">
            <TextInput value={name} onChangeText={setName} />
          </FormField>
          <FormField label="Email">
            <TextInput value={email} onChangeText={setEmail} />
          </FormField>
          <FormField label="Subject">
            <TextInput value={subject} onChangeText={setSubject} />
          </FormField>
          <FormField label="Message">
            <textarea
              value={message}
              onChange={e => setMessage(e.currentTarget.value)}
              css={{
                padding: theme.space.small,
                height: 100,
                borderRadius: 3,
                border: '0.5px solid',
                borderColor: theme.colors.border,
                '&:focus': {
                  border: '2px solid',
                  borderColor: theme.colors.secondary,
                  outline: 'none',
                },
              }}
            />
          </FormField>
          {showSuccessMessage && (
            <Text variant="detail" css={{ color: theme.colors.primary }}>
              Thanks for reaching out. We'll get back to you as soon as we can
              to help you start planning your adventure.
            </Text>
          )}
          <Button
            onClick={handleSubmit}
            css={{ marginTop: theme.space.medium, width: 200 }}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Contact
