/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useTheme } from '@emotion/react'
import { FC } from 'react'

interface FormFieldProps {
  label: string
}

const FormField: FC<FormFieldProps> = props => {
  const { label, children, ...rest } = props
  const theme = useTheme()
  return (
    <div
      css={{ display: 'flex', flexDirection: 'column', width: '100%' }}
      {...rest}
    >
      <span css={{ marginBottom: theme.space.small }}>{label}</span>
      {children}
    </div>
  )
}

export default FormField
