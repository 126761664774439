/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { FC } from 'react'
import BannerImage from '../components/BannerImage'
import Section from '../components/Section'
import NavBar from '../components/NavBar'
import { Image } from 'cloudinary-react'
import theme from '../theme/theme'
import { useHistory } from 'react-router-dom'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi'
import ToursRibbon from '../components/ToursRibbon'
import Text from '../components/Text'
import Button from '../components/Button'

const About: FC = () => {
  const history = useHistory()

  const openLink = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <div>
      <BannerImage>
        <NavBar
          css={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
          }}
        />
        <div
          css={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.3)',
          }}
        />
      </BannerImage>
      <div css={{ display: 'flex', justifyContent: 'center' }}>
        <div css={{ padding: '0 30px', maxWidth: 1200 }}>
          <Section
            header="Manga Tours"
            contentStyle={{ justifyContent: 'space-between' }}
          >
            <Image
              cloudName="manga-tours"
              publicId="mango_xjtfom.jpg"
              width="450"
              height="auto"
              css={{ borderRadius: 10 }}
            />
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                width: '50%',
                height: '100%',
              }}
            >
              <Text variant="body">
                <strong>Manga</strong> has several meanings in Malagasy. It can
                mean "blue", "mango", "beautiful", or "excellent", in addition
                to being part of my last name (Rakotoarimanga), I chose the name
                Manga Tours because I hope that your time with me will not be
                blue, but will allow you to experience the beauty of Madagascar
                with an excellent guide. Of course, one of my personal favorite
                things about Madagascar is mango season.
              </Text>
              <Button
                onClick={() =>
                  openLink(
                    'https://www.tripadvisor.com/Attraction_Review-g293809-d23551726-Reviews-Manga_Tours-Antananarivo_Antananarivo_Province.html',
                  )
                }
              >
                TripAdvisor Reviews
              </Button>
            </div>
          </Section>
          <Section
            header="Popular Tours"
            contentStyle={{
              display: 'grid',
              gridTemplateColumns: 'repeat(4, 1fr)',
              gap: 10,
              justifyItems: 'center',
            }}
          >
            <ToursRibbon />
          </Section>
          <Section
            header="About Me"
            contentStyle={{ alignItems: 'flex-start' }}
          >
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                paddingRight: theme.space.large,
              }}
            >
              <Text variant="body">
                My name is Faneva Rakotoarimanga. I live in Antananarivo, the
                capital of Madagascar, with my wife and two daughters. I am
                currently a remote student pursuing a degree in Hospitality and
                Tourism Management from Brigham Young University-Idaho. I love
                meeting new people and introducing the Malagasy culture to
                first-time visitors. I have over a decade of experience in the
                tourism industry. Initially, I started as a taxi driver in
                Antananarivo and then, after saving up enough to buy my own van,
                I transitioned into being a tour guide. I can't wait to help you
                experience an unforgettable journey across Madagascar.
              </Text>
              <div
                css={{
                  color: theme.colors.secondary,
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: theme.space.large,
                }}
              >
                <Text
                  variant="more"
                  css={{
                    marginRight: theme.space.medium,
                  }}
                  onClick={() => history.push('contact')}
                >
                  Get in Touch
                </Text>
                <HiOutlineArrowNarrowRight />
              </div>
            </div>
            <Image
              cloudName="manga-tours"
              publicId="fianakaviana-rakotoarimanga.jpg"
              width="300"
              crop="scale"
              css={{ borderRadius: theme.radii.heavy }}
            />
          </Section>
        </div>
      </div>
    </div>
  )
}

export default About
