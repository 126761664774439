/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useTheme } from '@emotion/react'
import { FC } from 'react'

interface FooterProps {}

const Footer: FC<FooterProps> = props => {
  const theme = useTheme()
  return (
    <footer
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        bordertop: '1px solid gray',
        height: theme.views.footerHeight,
        background: theme.colors.dark,
        padding: theme.space.medium,
      }}
    >
      <span
        css={{ color: theme.colors.light, fontSize: 12, fontStyle: 'italic' }}
      >
        &copy; 2021 Manga Tours. All Rights Reserved
      </span>
      <div></div>
    </footer>
  )
}

export default Footer
