/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useTheme } from '@emotion/react'
import { FC } from 'react'

interface ButtonProps {
  onClick: () => void
  color?: string
  className?: string
}

const Button: FC<ButtonProps> = props => {
  const theme = useTheme()
  const { onClick, color = theme.colors.secondary, children, ...rest } = props

  return (
    <button
      onClick={onClick}
      css={{
        backgroundColor: color,
        padding: '15px 20px',
        minWidth: 150,
        fontFamily: 'Montserrat',
        border: 'none',
        color: 'white',
        cursor: 'pointer',
        borderRadius: 3,
        boxShadow: 'box-shadow: 0px 4px 7px 0px rgba(174,167,167,0.75)',
      }}
      {...rest}
    >
      {children}
    </button>
  )
}

export default Button
