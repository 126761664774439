/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import React, { FC, Fragment } from 'react'
import TourCard from './TourCard'

interface ToursRibbonProps {}

const ToursRibbon: FC<ToursRibbonProps> = props => {
  return (
    <Fragment>
      <TourCard
        title="Southwest Coast"
        imgUrl="https://res.cloudinary.com/manga-tours/image/upload/v1613967873/baobabs.jpg"
        duration={7}
        sites={[
          'Antananarivo',
          'Morondava',
          'Baobab Alley',
          'Tsingy de Bemaraha',
        ]}
      />
      <TourCard
        title="Wildlife"
        imgUrl="https://res.cloudinary.com/manga-tours/image/upload/v1613967868/lemur-stump.jpg"
        duration={7}
        sites={[
          'Antananarivo',
          'Lemurs at Andasibe Park',
          'Whale watching in Ste. Marie',
        ]}
      />
      <TourCard
        title="North Coast"
        imgUrl="https://res.cloudinary.com/manga-tours/image/upload/v1613967868/emerald-sea.jpg"
        duration={10}
        sites={[
          'Antananarivo',
          'Tsingy Mena',
          'Nosy Be',
          'Diego-Suarez',
          'Emerald Sea',
        ]}
      />
      <TourCard
        title="Custom Tour"
        imgUrl="https://res.cloudinary.com/manga-tours/image/upload/v1613967869/rice-field.jpg"
        duration={'Fit your schedule'}
        sites={[
          "I'll help you create a completely personalized experience that fits your interests and schedule",
        ]}
      />
    </Fragment>
  )
}

export default ToursRibbon
