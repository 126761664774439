import { db } from './Firebase'
import { v4 } from 'uuid'

interface ContactFormInfo {
  name: string
  email: string
  subject: string
  message: string
}

export const submitContactForm = async (info: ContactFormInfo) => {
  const id = v4()
  const docRef = db.collection('contact-requests').doc(id)
  await docRef.set(info)
}
