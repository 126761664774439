/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useTheme } from '@emotion/react'
import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import Text from './Text'

interface TourCardProps {
  title: string
  imgUrl: string
  duration: number | string
  sites: string[]
}

const TourCard: FC<TourCardProps> = props => {
  const { imgUrl, title, duration, sites } = props
  const theme = useTheme()
  const history = useHistory()
  return (
    <div
      css={{
        position: 'relative',
        boxShadow: '0px 2px 4px 0px rgba(151,151,151,0.81)',
        height: 300,
        width: 250,
        backgroundImage: `url("${imgUrl}")`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        borderRadius: theme.radii.light,
        cursor: 'pointer',
        '&:hover .detail-overlay': {
          height: '40%',
          transitionDuration: '0.5s',
          transitionProperty: 'height',
        },
        '&:hover .details': {
          display: 'flex',
        },
      }}
      onClick={() => history.push('contact')}
    >
      <div
        css={{
          position: 'absolute',
          left: 10,
          top: 10,
          padding: theme.space.medium,
          backgroundColor: theme.colors.secondary,
          borderRadius: theme.radii.light,
        }}
      >
        <Text variant="h4" css={{ color: theme.colors.light }}>
          {title}
        </Text>
      </div>
      <div
        className="detail-overlay"
        css={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          height: '5%',
          padding: 15,
          backgroundColor: theme.colors.light,
          opacity: 0.95,
          borderRadius: theme.radii.light,
          transitionProperty: 'height',
          transitionDuration: '0.5s',
          overflow: 'hidden',
        }}
      >
        <div
          className="details"
          css={{
            flexDirection: 'column',
            display: 'none',
          }}
        >
          {sites.map(site => (
            <Text variant="detail" css={{ margin: '2.5px 0' }}>
              {site}
            </Text>
          ))}
        </div>
      </div>
      <Text
        variant="body"
        css={{
          position: 'absolute',
          bottom: 10,
          right: 10,
          color: theme.colors.accent,
        }}
      >
        {typeof duration === 'number' ? `${duration} days` : duration}
      </Text>
    </div>
  )
}

export default TourCard
