/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useTheme } from '@emotion/react'
import { FC } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'

interface NavItemProps extends NavLinkProps {}

const NavItem: FC<NavItemProps> = props => {
  const { children, ...rest } = props
  const theme = useTheme()
  return (
    <NavLink
      css={{
        color: theme.colors.light,
        padding: theme.space.medium,
        fontWeight: 700,
        position: 'relative',
        cursor: 'pointer',
        textDecoration: 'none',
        transitionDuration: '0.5s',
        transitionProperty: 'all',
        '&:visited': { color: theme.colors.light },
        '&:hover': {
          // color: theme.colors.primary,
          '&:after': {
            content: '""',
            width: '50%',
            position: 'absolute',
            bottom: 1,
            left: '25%',
            borderWidth: '0 0 1px',
            borderStyle: 'solid',
          },
        },
      }}
      // activeStyle={{
      //   color: theme.colors.primary,
      // }}
      {...rest}
    >
      {children}
    </NavLink>
  )
}

export default NavItem
