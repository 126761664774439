/**
 * 
  #####  Color Palette by Paletton.com
  #####  Palette URL: http://paletton.com/#uid=7000u0kllllaFw0g0qFqFg0w0aF
 * 
 */

// *** Primary color:

// export const PRIMARY = {
//   shade1: '#AA3939',
//   shade2: '#FFAAAA',
//   shade3: '#D46A6A',
//   shade4: '#801515',
//   shade5: '#550000',
// }

export const PRIMARY = {
  shade1: '#2D882D',
  shade2: '#88CC88',
  shade3: '#55AA55',
  shade4: '#116611',
  shade5: '#004400',
}

export const SECONDARY = {
  shade1: '#226666',
  shade2: '#669999',
  shade3: '#407F7F',
  shade4: '#0D4D4D',
  shade5: '#003333',
}

export const ACCENT = {
  shade1: '#AA6C39',
  shade2: '#FFD1AA',
  shade3: '#D49A6A',
  shade4: '#804515',
  shade5: '#552700',
}

export const OTHER = {
  white: '#FFF',
  black: '#000000',
  transparent: 'rgba(0,0,0,0.5)',
  gray1: '#AFAFAF',
  gray2: '#C6C6C6',
}

const colors = {
  primary: PRIMARY,
  secondary: SECONDARY,
  accent: ACCENT,
  other: OTHER,
}

export default colors

// *** Secondary color 2:
