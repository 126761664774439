/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useTheme } from '@emotion/react'
import { ChangeEvent, FC } from 'react'

interface TextInputProps {
  value: string
  onChangeText: (value: string) => void
}

const TextInput: FC<TextInputProps> = props => {
  const { value, onChangeText } = props
  const theme = useTheme()

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeText(event.target.value)
  }
  return (
    <input
      value={value}
      onChange={handleChange}
      css={{
        padding: theme.space.small,
        borderRadius: 3,
        border: '0.5px solid',
        height: 30,
        borderColor: theme.colors.border,
        '&:focus': {
          border: '2px solid',
          borderColor: theme.colors.secondary,
          outline: 'none',
        },
      }}
    />
  )
}

export default TextInput
