/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, CSSObject, useTheme } from '@emotion/react'
import { FC } from 'react'

interface SectionProps {
  header?: string
  contentStyle?: CSSObject
}

const Section: FC<SectionProps> = props => {
  const { header, contentStyle, children } = props
  const theme = useTheme()
  return (
    <div
      css={{
        padding: theme.space.large,
        display: 'grid',
        gridTemplateRows: '35px auto',
      }}
    >
      <h2
        css={{
          position: 'relative',
          justifySelf: 'flex-start',
          color: theme.colors.primary,
          fontFamily: 'Bitter',
          '&:after': {
            content: '""',
            width: '50%',
            left: '0%',
            position: 'absolute',
            bottom: -40,
            borderWidth: '0 0 5px',
            borderStyle: 'solid',
            borderColor: theme.colors.secondary,
          },
        }}
      >
        {header}
      </h2>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          padding: '50px 0px 0',
          ...contentStyle,
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default Section
