/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useTheme } from '@emotion/react'
import { FC } from 'react'
import NavItem from './NavItem'

interface NavBarProps {
  className?: string
}

const NavBar: FC<NavBarProps> = props => {
  const theme = useTheme()
  return (
    <div
      css={{
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 30px',
      }}
      {...props}
    >
      <div css={{ paddingLeft: theme.space.large }}>
        <h3 css={{ fontFamily: 'Montserrat', color: theme.colors.light }}>
          Manga Tours
        </h3>
      </div>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          paddingRight: theme.space.large,
        }}
      >
        <NavItem to="/" exact>
          About
        </NavItem>
        <NavItem to="/gallery">Gallery</NavItem>
        <NavItem to="/contact">Contact</NavItem>
      </div>
    </div>
  )
}

export default NavBar
