/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useTheme } from '@emotion/react'
import { FC, useEffect, useState } from 'react'
import { Image, Transformation } from 'cloudinary-react'
import { GalleryImage, getImages } from '../api/images'
import NavBar from '../components/NavBar'

const Gallery: FC = () => {
  const theme = useTheme()
  const [images, setImages] = useState<GalleryImage[]>([])

  const resolveImages = async () => {
    const imageList = await getImages()
    console.log(imageList)
    setImages(imageList)
  }

  useEffect(() => {
    resolveImages()
  }, [])

  console.log('render')
  return (
    <div>
      <NavBar css={{ backgroundColor: theme.colors.secondary }} />
      <div
        css={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-evenly',
          paddingLeft: theme.space.medium,
          paddingRight: theme.space.medium,
        }}
      >
        {images.map(image => (
          <Image
            key={image.cloudId}
            cloudName="manga-tours"
            publicId={image.cloudId}
            loading="lazy"
            css={{
              borderRadius: theme.radii.light,
              marginTop: theme.space.medium,
            }}
          >
            <Transformation
              width="500"
              height="500"
              gravity="faces"
              crop="fill"
            />
          </Image>
        ))}
      </div>
    </div>
  )
}

export default Gallery
