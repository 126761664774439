/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { FC } from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import Layout from './components/Layout'
import About from './containers/About'
import Contact from './containers/Contact'
import Gallery from './containers/Gallery'

const App: FC = () => {
  return (
    <Router>
      <Switch>
        <Layout>
          <Route path="/" exact>
            <About />
          </Route>
          <Route path="/gallery">
            <Gallery />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
        </Layout>
      </Switch>
    </Router>
  )
}

export default App
