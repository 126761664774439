/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useTheme } from '@emotion/react'
import { FC } from 'react'
import Footer from './Footer'

const Layout: FC = props => {
  const theme = useTheme()
  const { children } = props
  return (
    <div
      css={{
        height: '100vh',
        background: theme.colors.light,
        display: 'grid',
      }}
    >
      <div>
        <div css={{ minHeight: `calc(100% - ${theme.views.navHeight}px)` }}>
          {children}
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default Layout
