/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useTheme } from '@emotion/react'
import { FC } from 'react'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi'
import { Image } from 'cloudinary-react'
import { useHistory } from 'react-router-dom'

interface BannerImageProps {}

const BannerImage: FC<BannerImageProps> = props => {
  const { children } = props
  const theme = useTheme()
  const history = useHistory()
  return (
    <div css={{ width: '100%', height: 'fit-content', position: 'relative' }}>
      <Image
        cloudName="manga-tours"
        publicId="southmada_c2zuzo.jpg"
        width="100%"
      />
      <h1
        css={{
          position: 'absolute',
          top: '15%',
          left: '4%',
          fontFamily: 'Montserrat',
          fontSize: 93,
          textTransform: 'uppercase',
          color: theme.colors.light,
          width: '80%',
          zIndex: 2,
        }}
      >
        Come Explore With Us
        <div
          css={{
            fontSize: 25,
            letterSpacing: 1,
            color: theme.colors.light,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            transition: 'all 0.5s ease',
            '&:hover': {
              letterSpacing: 1.5,
            },
          }}
        >
          <h5
            css={{
              marginRight: theme.space.medium,
            }}
            onClick={() => history.push('contact')}
          >
            Book a Tour
          </h5>
          <HiOutlineArrowNarrowRight />
        </div>
      </h1>
      {children}
    </div>
  )
}

export default BannerImage
