import { Theme } from '@emotion/react'
import colors from './colors'

const theme: Theme = {
  colors: {
    primary: colors.primary.shade1,
    secondary: colors.secondary.shade1,
    accent: colors.accent.shade4,
    light: colors.other.white,
    dark: colors.other.black,
    transparent: colors.other.transparent,
    border: colors.other.gray1,
    secondaryBackground: colors.other.gray2,
  },
  views: {
    navHeight: 70,
    footerHeight: 60,
  },
  space: {
    small: 5,
    medium: 10,
    large: 20,
    xLarge: 50,
  },
  radii: {
    light: 5,
    heavy: 10,
  },
}

export default theme
